import { Draft, PayloadAction, createSlice } from "@reduxjs/toolkit";

const initialState = {
  theme: 'dark'
}

type GlobalReducerState = {
  theme: string
}

export const globalSlice = createSlice({
  name: 'global',
  initialState: initialState,
  reducers: {
    setTheme(state: Draft<GlobalReducerState>, action: PayloadAction<string>) {
      state.theme = action.payload;
    }
  }
});

const { reducer, actions } = globalSlice;

export const { setTheme } = actions;

export { reducer as globalReducer };
