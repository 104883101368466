import Onboard from "bnc-onboard";
import Web3 from "web3";
import configs from "../configs";
import Storage from "service/storage"

// set a variable to store instantiated web3
let web3: any;

const wallets = [
  { walletName: "metamask", preferred: true },
  {
    walletName: "walletConnect",
    infuraKey: configs.INFURA_KEY,
    preferred: true
  },
  {
    walletName: "walletLink",
    rpcUrl: configs.RPC_URL,
    appName: configs.WALLET_APP_NAME,
    preferred: true
  }
];

const initializationOptions = {
  dappId: configs.ONBOARD_API_KEY,
  networkId: configs.ONBOARD_NETWORK_ID,
  darkMode: false,
  subscriptions: {
    wallet: (wallet:any) => {
      // instantiate web3 when the user has selected a wallet
      web3 = new Web3(wallet.provider)
      Storage.set(configs.STORAGE.SELECTED_WALLET, wallet.name);
    }
  },
  walletSelect: {
    wallets: wallets 
 }
};

const onboard = Onboard(initializationOptions);

export { onboard, web3 };
