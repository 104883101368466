import { EthereumNetworkID } from "./model/EthereumNetwork";

const configs = {
    DEPLOY_URL: "",
    /* Wallet Info */
    ONBOARD_API_KEY: process.env.REACT_APP_ONBOARD_API_KEY,
    ONBOARD_NETWORK_ID: EthereumNetworkID.MainNetwork,
    FORTMATIC_KEY:  "",
    PORTIS_KEY: "",
    INFURA_KEY: "a6699e4ad89f4c4187e807f0709a360c",
    WALLET_APP_URL: "",
    CONTACT_EMAIL: "general@genesis.com",
    RPC_URL: "https://mainnet.infura.io/v3/a6699e4ad89f4c4187e807f0709a360c",
    WALLET_APP_NAME: "GENESIS",
    CURRENCY_API_URL: "https://api.binance.com/api/v1/ticker/price",

    /* API Info*/
    API: {
        BASE_URL: process.env.REACT_APP_API_POINT,
        AUTH_WITH_WALLET_URL: "auth/wallet",
    },
    
    /* STORAGE Values */
    STORAGE: {
        TOKEN: 'auth_token',
        SELECTED_WALLET: 'sellectedWallet'
    },

    /* ACCEPT Values */
    NFT_FILE_ACCEPT: "image/gif,image/png,image/jpeg,image/jpg,image/webp,video/mp4,video/x-m4v,.webm,.mp3",
    IMG_FILE_ACCEPT: "image/gif,image/png,image/jpeg,image/jpg,image/webp",

    /* Smart Contract */
    CONTRACT_ADDRESS: process.env.REACT_APP_CONTRACT_ADDRESS || '0x3702f4c46785bbd947d59a2516ac1ea30f2babf2',
};

export default configs;