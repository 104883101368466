import DarkDark from 'assets/imgs/dark_dark.svg';
import DarkLight from 'assets/imgs/dark_light.svg';
import LightDark from 'assets/imgs/light_dark.svg';
import LightLight from 'assets/imgs/light_light.svg';

import { setTheme } from 'store/global.slice';
import { useAppDispatch, useAppSelector } from 'store/hooks';
import '../../styles/components/_sidebar.scss';

const Sidebar = () => {
  const dispatch = useAppDispatch();
  const theme = useAppSelector(state => state.global.theme);

  return (
    <div className={`side-bar ${theme}`}>
      <div className="inner-wrapper">
        <label className="switch">
          <input type="checkbox" checked={theme === 'dark' ? true : false} onChange={() => {
            dispatch(setTheme(theme === 'dark' ? 'light' : 'dark'))
          }} />
          <span className="slider round"></span>
          <div className="images">
            <img src={theme !== 'dark' ? DarkLight : DarkDark} />
            <img src={theme !== 'dark' ? LightDark : LightLight} />
          </div>
        </label>
      </div>
    </div>
  )
}

export default Sidebar;