import React from 'react';
import { Container, Image, Button } from 'react-bootstrap';
import { Link } from 'react-router-dom';

import Logo from 'assets/imgs/logo.png';

interface Page404Props {}

const Page404: React.FC<Page404Props> = () => {

  return (
    <div className="page404-container">
      <Container>
        <div className="text-center w-100">
          <Image src={Logo} width="200" className="mx-auto mb-5" />
          <h2 className="h5 h2-md font-weight-bold mb-5">404. Page Not Found</h2>
          <Link to="/">
            <Button
              type="button"
              variant="primary"
              className="font-weight-bold font-size-md"
            >
              Go to Home
            </Button>
          </Link>
        </div>
      </Container>
    </div>
  );
};

export default Page404;