import Count from "../assets/imgs/count.svg";
import Countemp from "../assets/imgs/countemp.svg";
import TierType from "../assets/imgs/tiertype.svg";
import SpecialTierType from "../assets/imgs/specialTier.svg";
import Tier2 from "../assets/imgs/tier2.svg";

import buttonDiv from "./images/buttondiv.png";
import darkOuter from "./images/darkouter.png";
import inner from "./images/inner.png";
import outerDivT from "./images/outerdivT.png";

const KeyItem = ({ theme, keyName, keyType, value, onClick, image }) => {
  const outerDivStyle = {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    position: "relative",
    backgroundRepeat: "no-repeat",
    backgroundSize: "contain",
  };
  const innerDivStyle = {
    width: "247px",
    height: "327px",
    color: "#F0F0F0",

    display: "flex",

    alignItems: "center",
    flexDirection: "column",
  };

  const tierDivStyle = {
    position: "absolute",
    left: theme === "dark" ? "2.5rem" : "2rem",
    top: "0.5rem",
    zIndex: 1,
  };

  const genesisDivStyle = {
    color: theme === "dark" ? "white" : "#111121",
    fontFamily: "Bebas Neue",

    fontStyle: "normal",
    fontWeight: "600",
    lineHeight: "normal",

    width: theme === "dark" ? "100%" : "223px",

    height: theme === "dark" ? "160px" : "120px",

    borderRadius: "10px",

    backgroundImage: theme === "dark" ? "" : `url(${buttonDiv})`,
    backgroundRepeat: "no-repeat",
    display: "flex",

    justifyContent: theme === "dark" ? "space-around" : "center",

    paddingLeft: "10px",
    flexDirection: "column",
  };

  const imgDivStyle = {
    display: "flex",
    justifyContent: "center",
    borderRadius: "15px",
    marginTop: "8px",
    height: "158px",
    clipPath:
      theme === "dark"
        ? "polygon(22% 0, 100% 0, 100% 0%, 100% 80%, 100% 100%, 0% 100%, 0 80%, 0 30%)"
        : "",
    overflow: "hidden",
  };

  const buttonDivStyle = {
    width: theme === "dark" ? "175px" : "142px",
    height: theme === "dark" ? "65px" : "52px",
    background: "#ED4799",
    borderRadius: "10px",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    color: "white",
    fontSize: "18px",
    fontWeight: 600,
    cursor: "pointer",
    fontFamily: "Inter",
  };

  const counterDivOuterStyle = {
    display: "flex",
    justifyContent: "space-between",
    height: "47px",
    alignItems: "center",
  };

  const counterDivStyle = {
    width: "37px",
    height: "37px",
    backgroundRepeat: "no-repeat",
    backgroundImage: value === 0 ? `url(${Countemp})` : `url(${Count})`,

    backgroundPosition: "center",
    backgroundSize: "100% 100%",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    color: "white",
    marginRight:"5px"
  };
  const counterNumStyle = {
    color: value === 0 ? "#9D9DB5" : "white",
    fontSize: 16,
    fontFamily: "Bebas Neue",
  };
  const loaderStyle = {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    fontSize: "18px",
    fontWeight: "bold",
    color: "#888",
    borderRadius: "8px",
    boxShadow: "0px 2px 4px rgba(0, 0, 0, 0.1)",
  };

  console.log(keyType, keyName, "name");

  return (
    <div
      style={{
        width: "100%",
        height: "auto",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <div style={outerDivStyle}>
        <img src={theme === "dark" ? darkOuter : outerDivT} alt="-" />
        <div
          style={{
            position: "absolute",
            top: "0",
            left: "0",
            padding: "1rem",
            zIndex:0,
            width: "100%",
          }}
        >
          <div style={tierDivStyle}>
            <img
              src={
                keyName === "Special"
                  ? SpecialTierType
                  : keyName === "Tier 3"
                  ? Tier2
                  : TierType
              }
              alt=""
            />
            <span
              style={{
                position: "absolute",
                left: "50%",
                top: "50%",
                transform: "translate(-50%, -50%)",
                color: "white",
                fontFamily: "Inter",
                fontSize: 18,
                fontWeight: 700,
              }}
            >
              {keyName}
            </span>
          </div>

          <div style={innerDivStyle}>
            {theme !== "dark" && <img src={inner} />}
            <div
              style={{
                position: "absolute",
                top: "50%",
                left: "50%",
                transform: "translate(-50%, -50%)",
              }}
            >
              <div style={imgDivStyle}>
                {theme === "dark" ? (
                  <img src={image} alt="Key Image" width={227} height={147} />
                ) : (
                  <img src={image} alt="Key Image" width={177} height={127} />
                )}
              </div>
              <div style={genesisDivStyle}>
                <div style={counterDivOuterStyle}>
                  <div
                    style={{
                      textTransform: "uppercase",
                      fontFamily: "Bebas Neue",
                      fontSize: 26,
                      fontWeight: 400,
                    }}
                  >
                    {keyType}
                  </div>
                  <div style={counterDivStyle}>
                    <span style={counterNumStyle}>{value}</span>
                  </div>
                </div>
                <div style={buttonDivStyle} onClick={onClick}>
                  Use key
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
export default KeyItem;
