/* eslint-disable jsx-a11y/alt-text */
/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useLayoutEffect, useState } from "react";
import { injected } from "../Connector";
import { useWeb3React } from "@web3-react/core";
import SpinKey from "assets/imgs/spin-key.svg";
import { Button } from "react-bootstrap";
import Gold from "assets/imgs/gold.png";
import Key1 from "assets/imgs/key1.png";
import Key2 from "assets/imgs/key2.png";
import Key3 from "assets/imgs/key3.png";
import Logo from "assets/imgs/logo.svg";
import LogoDark from "assets/imgs/logo_dark.svg";
import Silver from "assets/imgs/silver.png";
import BigNumber from "bignumber.js";
import KeyItem from "components/KeyItem";
import Sidebar from "components/common/Sidebar";
import configs from "configs";
import { onboard } from "ethereum/OnBoard";
import React from "react";
import Modal from "react-modal";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import Slider from "react-slick";
import { getSelectedKey, getWalletAddress } from "store/User/user.selector";
import {
  connectUserWallet,
  disconnectUserWallet,
  setSelectedKey,
} from "store/User/user.slice";
import { useAppDispatch, useAppSelector } from "store/hooks";
import Swal from "sweetalert2";
import Web3 from "web3";

const ItemWrapper = (item: any) => {
  let arrTemp: number[] = new Array(item.val.amount).fill(0);

  const getImageUrl = () => {
    if (parseInt(item.val.itemId) / 10000 < 3) {
      return `https://gfcmetaimage.blob.core.windows.net/visual-update/weapons/${item.val.itemId}.png`;
    } else {
      // if (supportedImgs.findIndex((img) => img == item.val.itemId) >= 0) {
      return `https://gfcmetaimage.blob.core.windows.net/visual-update/weapons/${item.val.itemId}.png`;
      // } else {
      //   return `https://gfcmetaimage.blob.core.windows.net/p2e/images/p2e_unknownWeapon.png`
      // }
    }
  };

  return (
    <>
      {arrTemp.map((val: number, i: number) => (
        <img
          className={`w-100 p-3 pb-4 pt-1 w-cursor-pointer`}
          // src={(`${process.env.PUBLIC_URL}/imgs/GenesisKey/${item.val.itemType}/${item.val.itemId}.png`)}
          src={getImageUrl()}
          key={item.val.itemType + item.val.itemId + i}
          placeholder={item.val.itemType + item.val.itemId + i}
        />
      ))}
    </>
  );
};

const account = (web3: Web3) => {
  return new Promise((resolve, reject) => {
    web3.eth.getAccounts((err, accounts) => {
      if (err === null) {
        resolve(accounts[0]);
      } else {
        reject(err);
      }
    });
  });
};

const customStyles = {
  content: {
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    marginRight: "-50%",
    transform: "translate(-50%, -50%)",
    padding: "4rem 0rem",
  },
};

interface ItemProvider {
  transactionId: string;
  itemType: string;
  itemId: any;
  amount: string;
}

export default function Home() {
  // const { chainId, activate, deactivate, active, error } = useWeb3React();
  const theme = useAppSelector((state) => state.global.theme);
  const selectedKey = useSelector(getSelectedKey);
  const { chainId, activate, deactivate, active } = useWeb3React();
  const [connectedNetwork, setConnectedNetwork] = useState("");
  const [switchedNetwork, setSwitchedNetwork] = useState(false);
  const [isPolygonAdded, setIsPolygonAdded] = useState(false);
  const [AccountID, setAccountID] = useState("");
  const [walletAddr, setWalletAddr] = useState("");
  const [chainId2, setChainId2] = useState(0);
  const [ToggleConfirmed, setToggleConfirmed] = useState(false);
  const [isResultBoxShow, setIsResultBoxShow] = useState(false);
  const [ConfirmedItems, SetConfirmedItems] = useState<ItemProvider[]>([]);

  const [keys, setKeys] = useState([
    {
      image: Key1,
      name: "Tier 1 Genesis Key",
      type: "Tier 1",
      value: 0,
      keyId: 1,
    },
    {
      image: Key2,
      name: "Tier 2 Genesis Key",
      type: "Tier 2",
      value: 0,
      keyId: 2,
    },
    {
      image: Key3,
      name: "Tier 3 Genesis Key",
      type: "Tier 3",
      value: 0,
      keyId: 3,
    },
    {
      image: Gold,
      name: "Gold Key",
      type: "Special",
      value: 0,
      keyId: 4,
    },
    {
      image: Silver,
      name: "Silver Key",
      type: "Special",
      value: 0,
      keyId: 5,
    },
  ]);
  const [modalIsOpen, setIsOpen] = React.useState(false);
  const [SpinValue, setSpinValue] = useState(1);
  const [isSpinnerShow, setIsSpinnerShow] = useState(false);
  const [isLootBoxShow, setIsLootBoxShow] = useState(false);
  const [newTransactionId, setNewTransactionId] = useState("000");
  const [isRequestRandom, setIsRequestRandom] = useState(false);
  const [gCoinAmount, setGCoinAmount] = useState("0");

  const [SlilderSettings, setSlilderSettings] = useState({
    className: "center",
    infinite: false,
    centerPadding: "2rem",
    slidesToShow:
      window.screen.availWidth >= 1280
        ? 5
        : window.screen.availWidth >= 1024
        ? 4
        : window.screen.availWidth >= 768
        ? 3
        : window.screen.availWidth >= 320
        ? 2
        : 1,
    speed: 500,
    rows: 2,
    slidesPerRow: 1,
  });

  const [size, setSize] = useState([0, 0]);
  useLayoutEffect(() => {
    function updateSize() {
      setSize([window.innerWidth, window.innerHeight]);
    }
    window.addEventListener("resize", updateSize);
    updateSize();
    return () => window.removeEventListener("resize", updateSize);
  }, []);

  useEffect(() => {
    console.log("================= current width: ", size[0]);
    setSlilderSettings({
      className: "center",
      infinite: false,
      centerPadding: "2rem",
      slidesToShow:
        size[0] >= 1280
          ? 5
          : size[0] >= 1024
          ? 4
          : size[0] >= 768
          ? 3
          : size[0] >= 320
          ? 2
          : 1,
      speed: 500,
      rows: 2,
      slidesPerRow: 1,
    });
  }, [size]);

  console.log("active: ", active);
  console.log("chainId: ", chainId);
  const dispatch = useAppDispatch();
  const walletAddress = useAppSelector(getWalletAddress);
  const provider = window.ethereum;

  const web3 = new Web3(window.ethereum);

  if (window.ethereum) {
    window.ethereum.on("accountsChanged", function () {
      web3.eth.getAccounts(function (error, accounts) {
        //console.log("account changed to : ", accounts[0]);
        setWalletAddr(accounts[0]);
      });
    });
  } else {
    Swal.fire({
      icon: "error",
      title: "Oops...",
      text: `Please install MetaMask extension to your browser!`,
    });
  }

  useEffect(() => {
    if (walletAddress) {
      console.log("walletAddress: ", walletAddress);
      activate(injected);
    } else {
      localStorage.removeItem(configs.STORAGE.SELECTED_WALLET);
      deactivate();
    }
  }, [dispatch, walletAddress]);

  useEffect((): any => {
    console.log("+++++++++ useEffect: keys +++++++++++");
    let FinalchainId: number = 0;

    console.log(window.ethereum);
    if (!window.ethereum) return;
    window.ethereum
      .request({ method: "eth_requestAccounts" })
      .then(() => {
        const web3 = new Web3(window.ethereum);
        account(web3)
          .then((acc) => {
            web3.eth.net
              .getId()
              .then((result) => {
                FinalchainId = result;
                setChainId2(FinalchainId);
                const network = getNetworkName(FinalchainId);
                setConnectedNetwork(network);
                console.log("chain ID ==> " + FinalchainId);
                if (FinalchainId === 137 || FinalchainId === 80001) {
                  setIsPolygonAdded(true);
                  setSwitchedNetwork(true);
                  const web3 = new Web3(provider);
                  const CONTRACT_ABI =
                    FinalchainId === 137
                      ? require("../assets/GenesisKey_MainNet_ABI.json")
                      : require("../assets/GenesisKey_TestNet_ABI.json");
                  const CONTRACT_ADDRESS =
                    FinalchainId === 137
                      ? "0x3702f4c46785bbd947d59a2516ac1ea30f2babf2"
                      : "0x87A3D7c4730b77cBe59084c39710046Ee3b22B2a";
                  const KEY_NFT_ABI = require("../assets/Key_NFT_TestNet.json");
                  const KEY_NFT_CONTRACT_ADDRESS =
                    chainId2 === 137
                      ? "0x67a7aE8E3B3e1E58e2a105B4C7413a5506169476"
                      : "0x4C2360fc4ce1D75239f5f29eC0038490A8A5D252";
                  const keyNftContract = new web3.eth.Contract(
                    KEY_NFT_ABI,
                    KEY_NFT_CONTRACT_ADDRESS
                  );
                  account(web3).then((account) => {
                    let str_temp =
                      (account as string).slice(0, 6) +
                      "..." +
                      (account as string).slice(-4);
                    setAccountID(str_temp);
                    const revenueContract = new web3.eth.Contract(
                      CONTRACT_ABI,
                      CONTRACT_ADDRESS
                    );

                    if (FinalchainId === 137 || FinalchainId === 80001) {
                      for (let tokenId = 1; tokenId <= 3; tokenId++) {
                        revenueContract.methods
                          .balanceOf(account, tokenId)
                          .call({ from: account })
                          .then((result: string) => {
                            //0x18632ee94d6395e0cd1ea6c7ee702712baf7c6d9 :test https://opensea.io/GFCVault,  the address of our vault
                            let newData = [...keys];
                            newData[Number(tokenId) - 1].value = Number(result);
                            console.log("newData: ", newData);
                            setKeys(newData);
                          });
                      }

                      for (let tokenId = 4; tokenId <= 5; tokenId++) {
                        keyNftContract.methods
                          .balanceOf(account, tokenId - 4)
                          .call({ from: account })
                          .then((result: string) => {
                            //0x18632ee94d6395e0cd1ea6c7ee702712baf7c6d9 :test https://opensea.io/GFCVault,  the address of our vault
                            let newData = [...keys];
                            newData[Number(tokenId) - 1].value = Number(result);
                            console.log("newData: ", newData);
                            setKeys(newData);
                          });
                      }
                    }
                  });
                } else {
                  setSwitchedNetwork(false);
                }
              })
              .catch((err: any) => {
                console.log(err);
              });
          })
          .catch((err: any) => {
            console.log(err);
          });
      })
      .catch((err: any) => {
        console.log(err);
      });
  }, [active, chainId, switchedNetwork, ToggleConfirmed, walletAddr]);

  useEffect((): any => {
    console.log("newTransactionId changed! -------> ", newTransactionId);
    console.log("=== chainId: ", chainId2, " ===");

    const CONTRACT_ABI =
      chainId2 === 137
        ? require("../assets/LootBox_MainNet_ABI.json")
        : require("../assets/LootBox_TestNet_ABI.json");
    const CONTRACT_ADDRESS =
      chainId2 === 137
        ? "0xA15c4Aa7a2cfD5fd15F65A3ab928DC69b412C6b1"
        : "0xdaA67fBf1F3bCF276F95e3713750b0A117987419"; //address of LootBox

    const NODE_URL =
      chainId2 === 137
        ? "wss://polygon-mainnet.g.alchemy.com/v2/510KsHatNA0k1cJ_2GofoAkwsYV8SJUT"
        : "wss://polygon-mumbai.g.alchemy.com/v2/510KsHatNA0k1cJ_2GofoAkwsYV8SJUT";
    const event_web3 = new Web3(NODE_URL);

    const event_revenueContract = new event_web3.eth.Contract(
      CONTRACT_ABI,
      CONTRACT_ADDRESS
    );

    let options = {
      filter: {
        value: [],
      },
      fromBlock: 0,
    };

    event_revenueContract.events
      .WeaponMinted(options)
      .on("data", (event: any) => {
        if (event.returnValues.transactionId === newTransactionId) {
          // console.log("--------------------------------");
          // console.log(event.returnValues);
          // console.log("--------------------------------");

          let values = Object.values(event.returnValues);
          let result = {
            transactionId: event.returnValues.transactionId,
            itemType: "GenesisWeapon",
            itemId: values[4],
            amount: event.returnValues.amount,
          };
          console.log(result);
          if (!isLootBoxShow)
            SetConfirmedItems((ConfirmedItems) => [...ConfirmedItems, result]);
          // setIsSpinnerShow(false);
        }
      })
      .on("changed", (changed: any) => console.log(changed))
      .on("error", (err: any) => err)
      .on("connected", (str: string) => {});

    event_revenueContract.events
      .BYOPillMinted(options)
      .on("data", (event: any) => {
        if (event.returnValues.transactionId === newTransactionId) {
          // console.log("--------------------------------");
          // console.log(event.returnValues);
          // console.log("--------------------------------");

          let values = Object.values(event.returnValues);
          let result = {
            transactionId: event.returnValues.transactionId,
            itemType: "BYOPill",
            itemId: values[4],
            amount: event.returnValues.amount,
          };
          console.log(result);
          if (!isLootBoxShow)
            SetConfirmedItems((ConfirmedItems) => [...ConfirmedItems, result]);
          // setIsSpinnerShow(false);
        }
      })
      .on("changed", (changed: any) => console.log(changed))
      .on("error", (err: any) => err)
      .on("connected", (str: string) => {});

    event_revenueContract.events
      .MysterItemMinted(options)
      .on("data", (event: any) => {
        if (event.returnValues.transactionId === newTransactionId) {
          // console.log("--------------------------------");
          // console.log(event.returnValues);
          // console.log("--------------------------------");

          let values = Object.values(event.returnValues);
          let result = {
            transactionId: event.returnValues.transactionId,
            itemType: "MysteryItem",
            itemId: values[4],
            amount: event.returnValues.amount,
          };
          console.log(result);
          if (!isLootBoxShow)
            SetConfirmedItems((ConfirmedItems) => [...ConfirmedItems, result]);
          // setIsSpinnerShow(false);
        }
      })
      .on("changed", (changed: any) => console.log(changed))
      .on("error", (err: any) => err)
      .on("connected", (str: string) => {});
  }, [newTransactionId, chainId2]);

  const getNetworkName = (chain: any) => {
    switch (chain) {
      case 1:
        return "ETH mainnet";
      case 3:
        return "ETH Ropsten testnet";
      case 4:
        return "ETH Rinkeby testnet";
      case 5:
        return "ETH Goerli testnet";
      case 42:
        return "ETH Kovan testnet";
      case 100:
        return "ETH XDai testnet";
      case 137:
        return "Polygon mainnet";
      case 80001:
        return "Mumbai testnet";
      default:
        return "";
    }
  };

  const connect = () => {
    if (walletAddress) {
      deactivate();
      localStorage.removeItem(configs.STORAGE.SELECTED_WALLET);
      onboard.walletReset();
      dispatch(disconnectUserWallet());
    } else {
      dispatch(connectUserWallet());
    }
  };

  function swtichToPolygon(str_status: string) {
    setIsPolygonAdded(str_status === "add" ? true : false);
    const { ethereum } = window;
    ethereum
      .request({
        method: "wallet_switchEthereumChain",
        params: [{ chainId: "0x89" }],
      })
      .then((result: any) => {
        setSwitchedNetwork(true);
      })
      .catch((switchError: { code: number }) => {
        console.log("switchError.code: ", switchError.code);
        if (switchError.code === 4902 || switchError.code === -32603) {
          //4902 for desktop, -32603 for mobile
          ethereum
            .request({
              method: "wallet_addEthereumChain",
              params: [
                {
                  chainId: "0x89",
                  chainName: "Polygon",
                  nativeCurrency: {
                    name: "Matic",
                    symbol: "MATIC",
                    decimals: 18,
                  },
                  rpcUrls: [
                    "https://polygon-mainnet.g.alchemy.com/v2/510KsHatNA0k1cJ_2GofoAkwsYV8SJUT",
                  ],
                  // rpcUrls: ["https://polygon-rpc.com/"],
                  blockExplorerUrls: ["https://polygonscan.com/"],
                },
              ],
            })
            .catch((addError: any) => {
              console.log("Add network error!", addError);
            });
        }
      })
      .finally(() => {
        activate(injected);
      });
  }

  function openModal(keyId: number) {
    dispatch(setSelectedKey(keyId));
    setIsOpen(true);
  }

  function afterOpenModal() {
    // references are now sync'd and can be accessed.
  }

  function closeModal() {
    setIsOpen(false);
  }

  useEffect(() => {
    CheckBeforeOpenLootBox();
  }, [chainId, walletAddr]);

  const CheckBeforeOpenLootBox = async () => {
    setIsRequestRandom(false);
    // Check if the random number already generated
    if (typeof window !== "undefined" && !window.ethereum) return;

    console.log("=========== CheckBeforeOpenLootBox() called ===============");
    const provider = window.ethereum;
    const web3 = new Web3(provider);

    let chainId = await web3.eth.net.getId();
    console.log("chainId :::::::: ", chainId);

    const CONTRACT_ABI =
      chainId === 137
        ? require("../assets/LootBox_MainNet_ABI.json")
        : require("../assets/LootBox_TestNet_ABI.json");
    const CONTRACT_ADDRESS =
      chainId === 137
        ? "0xA15c4Aa7a2cfD5fd15F65A3ab928DC69b412C6b1"
        : "0xdaA67fBf1F3bCF276F95e3713750b0A117987419"; //address of LootBox

    {
      const contract = new web3.eth.Contract(CONTRACT_ABI, CONTRACT_ADDRESS);
      const payAmount = await contract.methods.gCoinPayAmount().call();
      setGCoinAmount(web3.utils.fromWei(payAmount));
    }

    account(web3).then((account) => {
      if (!account) return;

      const revenueContract = new web3.eth.Contract(
        CONTRACT_ABI,
        CONTRACT_ADDRESS
      );

      revenueContract.methods
        .getResult(account)
        .call()
        .then((result: any) => {
          console.log("getResult::::::: ", result); //Already rolled
          let rand_hex: string = web3.utils.randomHex(32);
          let rand_num: string = web3.utils.hexToNumberString(rand_hex);
          setNewTransactionId(rand_num);
          console.log("=== ", rand_num, " ===");
          Swal.fire({
            icon: "info",
            title: "Random Number already exist!",
            text: "You already generated random number last time! You will open lootbox from the last selection.",
          });
          setIsRequestRandom(true);
          setIsOpen(true);
          setIsSpinnerShow(true);
          ProcOpenLootBox(revenueContract, rand_num, account);
        })
        .catch((err: any) => {
          console.log(" getResult catch::::::: ", err);
          if (err.message.includes("Roll in progress")) {
            let rand_hex: string = web3.utils.randomHex(32);
            let rand_num: string = web3.utils.hexToNumberString(rand_hex);
            setNewTransactionId(rand_num);
            console.log("=== ", rand_num, " ===");
            Swal.fire({
              icon: "info",
              title: "Already requested Random Number!",
              text: "You already requested to generate random number last time! You will open lootbox from the last selection.",
            });
            setIsRequestRandom(true);
            setIsOpen(true);
            setIsSpinnerShow(true);
            RunOpenLootBox(revenueContract, rand_num, account);
          }
        });
    });
  };

  var intervalID = 0;
  function RunOpenLootBox(
    revenueContract: any,
    rand_num: string,
    account: any
  ) {
    intervalID = setInterval(
      ProcOpenLootBox,
      1000 * 30,
      revenueContract,
      rand_num,
      account
    ); //call ProcOpenLootBox after 30sec
    console.log("intervalID::::: ", intervalID);
  }

  function ProcOpenLootBox(
    revenueContract: any,
    rand_num: string,
    account: any
  ) {
    console.log(
      "after 30sec , ProcOpenLootBox() ==> intervalID::: ",
      intervalID
    );
    revenueContract.methods
      .getResult(account)
      .call()
      .then((result: number[]) => {
        console.log("Chainlink VRF result: " + result);
        clearInterval(intervalID);
        if (result?.length > 0) {
          console.log("chainlink VRF callbacked!");
          console.log("call OpenLootBox");

          // fetch('https://gasstation-mainnet.matic.network')
          // .then(response => response.json())
          // .then(json => {
          //   console.log(json);
          //   gas_fee = json.fast * 1000000000;  //unit of json.fastest is GWei
          //   console.log("======== fast gas fee for openLootBox: ", gas_fee);  // unit: Wei
          let gas_fee = 22222222222; //Wei
          web3.eth
            .getGasPrice(function (e, r) {
              console.log("=========== old gas fee: ", r);
              gas_fee = Number(r) * 3;
              console.log("=========== new gas fee: ", gas_fee);

              if (selectedKey <= 3) {
                revenueContract.methods
                  .openLootBox(rand_num)
                  .send({ from: account, gasPrice: gas_fee.toFixed() })
                  .on("receipt", function (receipt: any) {
                    //socket event will be happen before this 'receipt' occur
                    console.log("OpenLootBox called");
                    console.log(receipt);
                    setIsOpen(false);
                    setIsResultBoxShow(true);
                    setIsSpinnerShow(false);
                    setIsLootBoxShow(true);
                  })
                  .on("error", function (error: any, receipt: any) {
                    // If the transaction was rejected by the network with a receipt, the second parameter will be the receipt.
                    console.log(error);
                    console.log(receipt);
                    Swal.fire({
                      icon: "error",
                      title: "Oops...",
                      text: `Transaction failed`,
                    });
                    setIsOpen(false);
                    setIsSpinnerShow(false);
                  });
                //setIsSpinnerShow(false);
              } else if (selectedKey >= 4) {
                revenueContract.methods
                  .openGoldSilverLootBox(rand_num)
                  .send({ from: account, gasPrice: gas_fee.toFixed() })
                  .on("receipt", function (receipt: any) {
                    //socket event will be happen before this 'receipt' occur
                    console.log("OpenGoldSilverLootBox called");
                    console.log(receipt);
                    setIsOpen(false);
                    setIsResultBoxShow(true);
                    setIsSpinnerShow(false);
                    setIsLootBoxShow(true);
                  })
                  .on("error", function (error: any, receipt: any) {
                    // If the transaction was rejected by the network with a receipt, the second parameter will be the receipt.
                    console.log(error);
                    console.log(receipt);
                    Swal.fire({
                      icon: "error",
                      title: "Oops...",
                      text: `Transaction failed`,
                    });
                    setIsOpen(false);
                    setIsSpinnerShow(false);
                  });
                //setIsSpinnerShow(false);
              }
            })
            .catch((err: any) => {
              console.log(err);
              Swal.fire({
                icon: "error",
                title: "Oops...",
                text: `Can not get the gas fee!`,
              });
              setIsOpen(false);
              setIsSpinnerShow(false);
            });
        }
      })
      .catch((err: any) => {
        console.log(err);
        console.log("getResult() will be called after 30sec");
        // setIsOpen(false);
        // setIsSpinnerShow(false);
      });
  }

  function confirmModal(str_status: string) {
    if (isSpinnerShow) return;
    console.log(str_status);

    console.log("current balance", keys[selectedKey - 1].value);
    if (SpinValue > keys[selectedKey - 1].value) {
      Swal.fire({
        icon: "error",
        title: "Oops...",
        text: `You don't have enough keys!`,
        confirmButtonColor: "#EE4699",
        background: theme === "dark" ? "#231E5E" : "white",
        color: theme === "dark" ? "white" : "#231E5E",
      });
      return;
    }

    const web3 = new Web3(provider);
    const CONTRACT_ABI =
      chainId2 === 137
        ? require("../assets/LootBox_MainNet_ABI.json")
        : require("../assets/LootBox_TestNet_ABI.json");
    const CONTRACT_ADDRESS =
      chainId2 === 137
        ? "0xA15c4Aa7a2cfD5fd15F65A3ab928DC69b412C6b1"
        : "0xdaA67fBf1F3bCF276F95e3713750b0A117987419"; //address of LootBox
    account(web3).then(async (account) => {
      let str_temp =
        (account as string).slice(0, 6) + "..." + (account as string).slice(-4);
      setAccountID(str_temp);
      const revenueContract = new web3.eth.Contract(
        CONTRACT_ABI,
        CONTRACT_ADDRESS
      );

      let rand_hex: string = web3.utils.randomHex(32);
      let rand_num: string = web3.utils.hexToNumberString(rand_hex);
      setNewTransactionId(rand_num);
      console.log("=== ", rand_num, " ===");

      const GENESIS_CONTRACT_ABI = require("../assets/GenesisKey_MainNet_ABI.json");
      const GENESIS_CONTRACT_ADDRESS =
        "0x3702f4c46785bbd947d59a2516ac1ea30f2babf2";
      const KEY_NFT_ABI = require("../assets/Key_NFT_TestNet.json");
      const KEY_NFT_CONTRACT_ADDRESS =
        chainId2 === 137
          ? "0x67a7aE8E3B3e1E58e2a105B4C7413a5506169476"
          : "0x4C2360fc4ce1D75239f5f29eC0038490A8A5D252";
      const keyNftContract = new web3.eth.Contract(
        KEY_NFT_ABI,
        KEY_NFT_CONTRACT_ADDRESS
      );
      const genesisKeyContract = new web3.eth.Contract(
        GENESIS_CONTRACT_ABI,
        GENESIS_CONTRACT_ADDRESS
      );

      if (selectedKey <= 3) {
        const isApprovedForAll = await genesisKeyContract.methods
          .isApprovedForAll(account, CONTRACT_ADDRESS)
          .call();

        if (!isApprovedForAll) {
          await genesisKeyContract.methods
            .setApprovalForAll(CONTRACT_ADDRESS, true)
            .send({
              from: account,
              gasPrice: 999999,
            });
        }
      }

      if (selectedKey >= 4) {
        const isKeyApprovedForAll = await keyNftContract.methods
          .isApprovedForAll(account, CONTRACT_ADDRESS)
          .call();
        const gasPrice = await web3.eth.getGasPrice();

        if (!isKeyApprovedForAll) {
          await keyNftContract.methods
            .setApprovalForAll(CONTRACT_ADDRESS, true)
            .send({
              from: account,
              gasPrice,
            });
        }

        const GCOIN_CONTRACT_ADDRESS =
          "0x071ac29d569a47ebffb9e57517f855cb577dcc4c";
        const GCOIN_ABI = require("../assets/GCOIN_ABI.json");
        const gCoinContract = new web3.eth.Contract(
          GCOIN_ABI,
          GCOIN_CONTRACT_ADDRESS
        );

        const allowance = await gCoinContract.methods
          .allowance(account, CONTRACT_ADDRESS)
          .call();

        if (
          new BigNumber(allowance).lte(
            new BigNumber(web3.utils.toWei("100000"))
          )
        ) {
          const gasPrice = await web3.eth.getGasPrice();
          await gCoinContract.methods
            .approve(CONTRACT_ADDRESS, web3.utils.toWei("1000000"))
            .send({
              from: account,
              gasPrice,
            });
        }
      }

      let gas_fee = 22222222222; //Wei
      // fetch('https://gasstation-mainnet.matic.network')
      // .then(response => response.json())
      // .then(json => {
      // console.log(json);
      // gas_fee = json.fast * 1000000000;  //unit of json.fastest is GWei
      // console.log("======== fast gas fee for getRandom(): ", gas_fee);  // unit: Wei
      web3.eth
        .getGasPrice(function (e, r) {
          console.log("=========== old gas fee: ", r);
          gas_fee = Number(r) * 3;
          console.log("=========== new gas fee: ", gas_fee);
          setIsSpinnerShow(true);

          revenueContract.methods
            .getResult(account)
            .call()
            .then((result: any) => {
              console.log("getResult::::::: ", result); //Already rolled
              ProcOpenLootBox(revenueContract, rand_num, account);
            })
            .catch((err: any) => {
              console.log(" getResult catch::::::: ", err);

              if (err.message.includes("Dice not rolled")) {
                console.log("call getRandom()");
                const randomMethod =
                  selectedKey <= 3
                    ? revenueContract.methods.getRandom
                    : revenueContract.methods.getKeyRandom;

                randomMethod(
                  selectedKey <= 3 ? selectedKey : selectedKey - 4,
                  SpinValue
                )
                  .send({ from: account, gasPrice: gas_fee.toFixed() })
                  .then((receipt: any) => {
                    RunOpenLootBox(revenueContract, rand_num, account);
                  })
                  .catch((err: any, receipt: any) => {
                    console.log(err);
                    console.log(receipt);
                    if (
                      err.message ===
                      "MetaMask Tx Signature: User denied transaction signature."
                    ) {
                      Swal.fire({
                        icon: "info",
                        title: "Oops...",
                        text: `You have canceled the transaction!`,
                      });
                      setIsOpen(false);
                      setIsSpinnerShow(false);
                    } else {
                      Swal.fire({
                        icon: "info",
                        title: "Oops...",
                        text: err.message,
                      });
                      setIsOpen(false);
                      setIsSpinnerShow(false);
                    }
                  });
              } else if (err.message.includes("Roll in progress")) {
                RunOpenLootBox(revenueContract, rand_num, account);
                // Swal.fire({
                //   icon: 'info',
                //   title: 'Oops...',
                //   text: `Generating a random number! Please try again after 3min!`,
                // })
                // setIsOpen(false);
                // setIsSpinnerShow(false);
              }
            });
        })
        .catch((err: any) => {
          console.log(err);
          Swal.fire({
            icon: "error",
            title: "Oops...",
            text: `Can not get the gas fee!`,
          });
          setIsOpen(false);
          setIsSpinnerShow(false);
        });
    });
  }

  function SpinValueChanged(amount: number) {
    setSpinValue(SpinValue + amount < 1 ? 1 : SpinValue + amount);
  }

  function SpinValueFromInput(amount: number) {
    setSpinValue(amount > 0 ? amount : 1);
  }

  function returnClicked(str_status: string) {
    console.log(str_status);
    setIsResultBoxShow(false);
    SetConfirmedItems([]);
    setIsSpinnerShow(false);
    setToggleConfirmed(!ToggleConfirmed);
    setIsLootBoxShow(false);
  }

  function getModalText() {
    if (selectedKey <= 3) {
      return `Tier ${selectedKey}`;
    } else {
      return selectedKey === 4 ? "Gold" : "Silver";
    }
  }

  Modal.setAppElement("body");
  return (
    <div className={`app-container ${theme}`}>
      <Sidebar />
      <div className="app-header d-flex justify-content-between align-items-center">
        <div className="logo d-flex align-items-center">
          <div className="logo-img">
            <Link to="/">
              <img className="h-100" src={theme === "dark" ? Logo : LogoDark} />
            </Link>
          </div>
          <span className="header-text">Genesis Keys</span>
        </div>
        <div className="connection d-flex justify-content-between align-items-center">
          <div className="div-connect-btn">
            {connectedNetwork ? (
              <div className="d-flex align-items-center mr-4">
                <div
                  style={{
                    display: "inline-block",
                    width: 14,
                    height: 14,
                    background: "#00B9BA",
                    borderRadius: 14,
                    marginRight: 10,
                  }}
                ></div>
                {connectedNetwork}
              </div>
            ) : (
              <div className="d-flex align-items-center  mr-4">
                <div
                  style={{
                    display: "inline-block",
                    width: 14,
                    height: 14,
                    background: "#F11616",
                    borderRadius: 14,
                    marginRight: 10,
                  }}
                ></div>
                Not Connected
              </div>
            )}
          </div>
          <Button className="connect-btn" variant="contained" onClick={connect}>
            {connectedNetwork ? AccountID : "Connect"}
          </Button>
        </div>
      </div>
      {!switchedNetwork && isPolygonAdded && (
        <div className="d-flex align-items-bottom bg-white w-100 justify-content-center px-3 py-1">
          <span className="font-0-2 px-2">
            You need to connect to the supported network
          </span>
          <Button
            variant="contained"
            className="switchNet-btn px-2"
            onClick={() => swtichToPolygon("add")}
          >
            <span className="font-0-1">Switch Network</span>
          </Button>
        </div>
      )}
      {!isResultBoxShow ? (
        <div className="resultBox-flex">
          {chainId2 !== 0 &&
            (!switchedNetwork && !isPolygonAdded ? (
              <div className="vertical-center">
                <div className="message-box mx-auto">
                  <div className="box-text">
                    In order to open loot boxes, <br />
                    you will have to be on the Polygon network
                  </div>
                  <div className="d-flex justify-content-center">
                    <Button
                      className="add-btn"
                      variant="contained"
                      onClick={() => swtichToPolygon("add")}
                    >
                      Add Polygon network
                    </Button>
                  </div>
                </div>
              </div>
            ) : (
              <div className="keys-group">
                {/* <div className="d-flex align-items-center p-2 w-100 justify-content-center flex-wrap main-keys"> */}
                {keys.map((key, index) => (
                  <div className="group-item" key={index}>
                    {/* <img src={KeyBackground} className="background w-100" /> */}
                    <KeyItem
                      image={key.image}
                      onClick={() => openModal(key.keyId)}
                      theme={theme}
                      keyName={key.type}
                      keyType={key.name}
                      value={key.value}
                    />
                  </div>
                ))}``
                {/* </div> */}
              </div>
            ))}
          {/* {!!error && (
              <h4 style={{ marginTop: "1rem", marginBottom: "0" }}>
                {getErrorMessage(error)}
              </h4>
            )} */}
          <Modal
            isOpen={modalIsOpen}
            onAfterOpen={afterOpenModal}
            onRequestClose={closeModal}
            style={customStyles}
            className={`Modal Modal-style ${theme}`}
            overlayClassName="Overlay"
          >
            <div className="modal-close" onClick={closeModal}>
              x
            </div>
            {!isRequestRandom ? (
              <div className="d-flex flex-column align-items-center justify-content-around mx-auto w-75 h-100">
                <div className="d-flex flex-column align-items-center justify-content-around">
                  <div className="font-2 text-uppercase main-title pb-0">
                    You have chosen to use {getModalText()} Keys
                  </div>
                  {selectedKey >= 4 && (
                    <div className="font-1 p-1 sub-title">
                      It costs {gCoinAmount} GCOIN to use 1 key
                    </div>
                  )}
                  <div className="pb-2 font-1 sub-title">
                    How many would you like to use?
                  </div>
                </div>
                <div className="d-flex flex-row pb-3 align-items-center gap-3">
                  <Button
                    variant="contained"
                    className="spin-btn spin-left"
                    onClick={() => SpinValueChanged(-1)}
                  >
                    -
                  </Button>
                  <div className="spin-center">
                    <div className="outer-circle">
                      <div className="inner-circle">
                        <div className="key-wrapper">
                          <img src={SpinKey} />
                          <input
                            className="spinvalue-size text-center border border-dark border-2 font-2"
                            type="number"
                            id="input_amount"
                            name="input_amount"
                            min="1"
                            value={SpinValue}
                            onChange={(e) =>
                              SpinValueFromInput(Number(e.target.value))
                            }
                            required
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                  <Button
                    variant="contained"
                    className="spin-btn spin-right"
                    onClick={() => SpinValueChanged(1)}
                  >
                    +
                  </Button>
                </div>
                <Button
                  variant="contained"
                  className="confirm-btn"
                  onClick={() => confirmModal("confirm")}
                >
                  {isSpinnerShow ? (
                    <div className="cssload-container">
                      <div className="cssload-zenith"></div>
                    </div>
                  ) : (
                    "Confirm"
                  )}
                </Button>
              </div>
            ) : (
              <div className="d-flex flex-column align-items-center justify-content-around mx-auto w-75 h-100">
                <div className="text-1 font-2">
                  Opening loot box from last selection
                </div>
                <Button variant="contained" className="confirm-btn">
                  <div className="cssload-container">
                    <div className="cssload-zenith"></div>
                  </div>
                </Button>
              </div>
            )}
          </Modal>
        </div>
      ) : (
        <div className="container p-3" style={{ height: "85vh" }}>
          {/* <div className="d-flex flex-column px-2 py-5 bg-white justify-content-around align-items-start lootbox"> */}
          <div className="px-2 py-3 bg-white lootbox w-90 mx-auto">
            <div className="text-2 pt-3">
              <h5>Loot box results:</h5>
            </div>
            <div className="d-flex flex-column justify-content-around align-items-center align-content-around w-100 item-image">
              {isSpinnerShow ? (
                <div className="cssload-container">
                  <div className="cssload-zenith cssload-zenith-black"></div>
                </div>
              ) : (
                ""
              )}
              <div className="justify-content-start align-items-center align-content-around flex-wrap w-75 px-auto py-3">
                <Slider {...SlilderSettings}>
                  {ConfirmedItems.map((item) => (
                    <ItemWrapper val={item} />
                  ))}
                </Slider>
              </div>
              <Button
                variant="contained"
                className="mt-2 mb-2 confirm-btn return-btn"
                onClick={() => returnClicked("return")}
              >
                Return
              </Button>
            </div>
          </div>
        </div>
      )}
    </div>
  );
}
